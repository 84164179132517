function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import Cookies from "js-cookie";
import { COOKIE_UNCATEGORIZED, COOKIE_CATEGORY_GTM_DATALAYER_NOT_SET, COOKIE_CATEGORY_NOT_APPROVED } from "constants/bigbrain-event-types";
import { COOKIES_CONFIG, DEFAULT_COOKIES_SETTINGS } from "constants/cookies";
import { getDataLayerVariable } from "client/services/gtm-service/gtm-service";
import { COOKIE_CATEGORIES_GTM_MAPPINGS } from "constants/cookies-categories";
import { isRunningInClient } from "services/dom-utils-service";
import { trackEvent } from "/services/bigbrain-service";
Cookies.defaults = DEFAULT_COOKIES_SETTINGS;
export const getAllCookies = ()=>{
    return Cookies.get();
};
export const getCookie = (cookieKey)=>{
    return Cookies.get(cookieKey);
};
export const setCookie = (cookieKey, cookieValue, options = {})=>{
    const cookieSettings = COOKIES_CONFIG[cookieKey] || {};
    const runningInClient = isRunningInClient();
    if (!runningInClient) {
        console.log(`setCookie ${cookieKey} called on server side`);
    }
    if (Object.keys(cookieSettings).length === 0 || !(cookieSettings === null || cookieSettings === void 0 ? void 0 : cookieSettings.category)) {
        console.error(`Cookie ${cookieKey} is not categorized`);
        trackEvent(COOKIE_UNCATEGORIZED, {
            kind: cookieKey
        });
    }
    if (isInClientAndCookieCategoryNotApproved(cookieSettings.category)) {
        // console.log(`not writing cookie ${cookieKey}`);
        // if cookiehub isn't set  verify we are not set to false and uncomment the return
        trackEvent(COOKIE_CATEGORY_NOT_APPROVED, {
            kind: cookieSettings.category,
            info1: cookieKey,
            info2: cookieValue
        });
    // return;
    }
    return Cookies.set(cookieKey, cookieValue, _object_spread({}, DEFAULT_COOKIES_SETTINGS, cookieSettings.options, options));
};
export const setCookieIfNeeded = (cookieKey, cookieValue, options = {}, bigBrainEventName, eventData = {})=>{
    if (!getCookie(cookieKey) && cookieValue) {
        setCookie(cookieKey, cookieValue, options);
        if (bigBrainEventName) {
            trackEvent(bigBrainEventName, eventData);
        }
    }
};
export const removeCookie = (cookieKey)=>{
    return Cookies.remove(cookieKey);
};
const isCookieCategoryApproved = (category)=>{
    var _COOKIE_CATEGORIES_GTM_MAPPINGS_category;
    const gtmAllowedDataLayerVariable = (_COOKIE_CATEGORIES_GTM_MAPPINGS_category = COOKIE_CATEGORIES_GTM_MAPPINGS[category]) === null || _COOKIE_CATEGORIES_GTM_MAPPINGS_category === void 0 ? void 0 : _COOKIE_CATEGORIES_GTM_MAPPINGS_category.gtmAllowedDataLayerVariable;
    const categoryApproved = getDataLayerVariable(gtmAllowedDataLayerVariable);
    if (typeof categoryApproved == "undefined" || categoryApproved === null) {
        //will uncomment BB event once fully released
        trackEvent(COOKIE_CATEGORY_GTM_DATALAYER_NOT_SET, {
            kind: category
        });
        console.log(`cookie category ${category} not set in GTM datalayer`);
    }
    return categoryApproved !== false;
};
export const isInClientAndCookieCategoryNotApproved = (category)=>{
    if (!isRunningInClient()) return false; //Approval data resides in GTM data layer can't access it from server code
    return !isCookieCategoryApproved(category);
};
