import Colors from "styles/colors";
import { AMETHYST_PURPLE_COLOR, BACKGROUND_GRAY_COLOR_NAME, BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01, BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02, BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02, BRAND_BLACK_BACKGROUND_COLOR, BRAND_BLACK_TEXT_COLOR, BRAND_DARK_COLOR, BRAND_GREEN_DARK_TINT_01, BRAND_GREEN_DARKER_TINT_02, BRAND_PRIMARY_GREEN, BRAND_PRIMARY_RED, BRAND_PRIMARY_YELLOW, BRAND_RED_DARK_TINT_01, BRAND_RED_DARKER_TINT_02, BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01, BRAND_WORK_MANAGEMENT_PRIMARY_COLOR, BRAND_YELLOW_DARK_TINT_01, BRAND_YELLOW_DARKER_TINT_02, CRM_DARK_TINT_01, CRM_DARK_TINT_01_COLOR_NAME, CRM_DARKER_TINT_02, CRM_DARKER_TINT_02_COLOR_NAME, CRM_GREEN_COLOR_NAME, CRM_LIGHT_TINT_02, CRM_LIGHTER_TINT_01, CRM_PRIMARY_COLOR, DARK_SURFACE_COLOR_NAME, DEEP_NAVY_BLUE_COLOR, DEV_DARK_TINT_01, DEV_DARKER_TINT_02, DEV_GREEN_COLOR_NAME, DEV_LIGHT_TINT_02, DEV_LIGHTER_TINT_01, DEV_LIGHTER_TINT_01_COLOR_NAME, DEV_ORANGE_COLOR_NAME, DEV_PRIMARY_COLOR, ELEVATE_BANNER_GRADIANT_COLOR_NAME, FIRST_FOLD_MOTION_INDIGO_COLOR, GRADIANT_BRAND_GRAY_COLOR_NAME, INDIGO_BLUE_COLOR, IRIS_COLOR_NAME, LIGHT_CRM_GREEN_COLOR_NAME, LIGHT_DEV_GREEN_COLOR_NAME, LIGHT_MARKETING_RED_COLOR_NAME, LIGHT_PROJECTS_ORANGE_COLOR_NAME, LIGHT_WORK_OS_IRIS_COLOR_NAME, MARKETER_DARK_TINT_01, MARKETER_DARKER_TINT_02, MARKETER_LIGHT_TINT_02, MARKETER_LIGHTER_TINT_01, MARKETER_PRIMARY_COLOR, MARKETING_RED_COLOR_NAME, MIDNIGHT_PURPLE_COLOR, PROJECTS_BLUE_COLOR_NAME, PROJECTS_DARK_TINT_01, PROJECTS_DARKER_TINT_02, PROJECTS_DARKEST_TINT_03, PROJECTS_LIGHT_TINT_02, PROJECTS_LIGHTER_TINT_01, PROJECTS_ORANGE_COLOR_NAME, PROJECTS_PRIMARY_COLOR, RACKLEY_BLUE, RIVERSTONE_COLOR_NAME, ROUGE_RED, SAPPHIRE_PURPLE_COLOR, SIGN_UP_WITH_PRODUCTS_COLOR_NAME, SNOW_GREY, SPACE_BLUE_COLOR_NAME, SPACE_BLUE_GRADIANT_COLOR_NAME, SPACE_BLUE_VIDEO_ONLY_COLOR_NAME, SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME, SPRING_GREEN, WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME, BRAND_LIGHT_COLOR_NAME } from "styles/color-consts";
export const PURPLE_THEME_ID = "purple";
export const IRIS_THEME_ID = "iris";
export const ROYAL_THEME_ID = "royal";
export const GREEN_THEME_ID = "green";
export const YELLOW_THEME_ID = "yellow";
export const ORANGE_THEME_ID = "orange";
export const RIVERSTONE_THEME_ID = "riverstone";
export const PINK_THEME_ID = "pink";
export const BLUE_THEME_ID = "blue";
export const ENTERPRISE_THEME_ID = "enterprise";
export const GRADIANT_GRAY_THEME_ID = "gradiant-gray";
export const GRADIANT_BRAND_GRAY_THEME_ID = GRADIANT_BRAND_GRAY_COLOR_NAME;
export const GRADIANT_PINK_THEME_ID = "gradiant-pink";
export const GRADIANT_ELEVATE_THEME_ID = "gradiant-elevate";
export const BLACK_THEME_ID = "black";
export const WHITE_THEME_ID = "white";
export const LIGHT_BLUE_THEME_ID = "light-blue";
export const LIME_THEME_ID = "lime";
export const PROJECTS_ORANGE_THEME_ID = "projects-orange";
export const MARKETING_RED_THEME_ID = "marketing-red";
export const CRM_GREEN_THEME_ID = "crm-green";
export const DEV_GREEN_THEME_ID = "dev-green";
export const SERVICE_RED_THEME_ID = "service-red";
export const WORKOS_IRIS_THEME_ID = "workos-iris";
export const GRAY_THEME_ID = "background-gray";
export const SPACE_BLUE_THEME_ID = "space-blue";
export const SIGN_UP_WITH_PRODUCTS_BACKGROUND_ID = SIGN_UP_WITH_PRODUCTS_COLOR_NAME;
export const SPACE_BLUE_GRADIANT_THEME_ID = SPACE_BLUE_GRADIANT_COLOR_NAME;
export const BACKGROUND_GRAY_THEME_ID = BACKGROUND_GRAY_COLOR_NAME;
export const ELEVATE_THEME_ID = ELEVATE_BANNER_GRADIANT_COLOR_NAME;
export const SPACE_BLUE_VIDEO_THEME_ID = SPACE_BLUE_VIDEO_ONLY_COLOR_NAME;
export const SPACE_BLUE_VIDEO_DESKTOP_THEME_ID = SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME;
export const LIGHT_WORK_OS_IRIS_THEME_ID = LIGHT_WORK_OS_IRIS_COLOR_NAME;
export const LIGHT_DEV_GREEN_THEME_ID = LIGHT_DEV_GREEN_COLOR_NAME;
export const LIGHT_MARKETING_RED_THEME_ID = LIGHT_MARKETING_RED_COLOR_NAME;
export const LIGHT_CRM_GREEN_THEME_ID = LIGHT_CRM_GREEN_COLOR_NAME;
export const LIGHT_PROJECTS_ORANGE_THEME_ID = LIGHT_PROJECTS_ORANGE_COLOR_NAME;
export const BRAND_WORK_MANAGEMENT_PRIMARY_THEME_ID = BRAND_WORK_MANAGEMENT_PRIMARY_COLOR;
export const BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01_THEME_ID = BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01;
export const BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02_THEME_ID = BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02;
export const BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01_THEME_ID = BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01;
export const BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02_THEME_ID = BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02;
export const BRAND_PRIMARY_YELLOW_THEME_ID = BRAND_PRIMARY_YELLOW;
export const BRAND_YELLOW_DARK_TINT_01_THEME_ID = BRAND_YELLOW_DARK_TINT_01;
export const BRAND_YELLOW_DARKER_TINT_02_THEME_ID = BRAND_YELLOW_DARKER_TINT_02;
export const BRAND_PRIMARY_RED_THEME_ID = BRAND_PRIMARY_RED;
export const BRAND_RED_DARK_TINT_01_THEME_ID = BRAND_RED_DARK_TINT_01;
export const BRAND_RED_DARKER_TINT_02_THEME_ID = BRAND_RED_DARKER_TINT_02;
export const BRAND_PRIMARY_GREEN_THEME_ID = BRAND_PRIMARY_GREEN;
export const BRAND_GREEN_DARK_TINT_01_THEME_ID = BRAND_GREEN_DARK_TINT_01;
export const BRAND_GREEN_DARKER_TINT_02_THEME_ID = BRAND_GREEN_DARKER_TINT_02;
export const WHITE_COLOR_NAME_THEME_ID = WHITE_COLOR_NAME;
export const BRAND_DARK_COLOR_THEME_ID = BRAND_DARK_COLOR;
export const BRAND_BLACK_BACKGROUND_COLOR_THEME_ID = BRAND_BLACK_BACKGROUND_COLOR;
export const CRM_PRIMARY_THEME_ID = CRM_PRIMARY_COLOR;
export const CRM_LIGHTER_TINT_01_THEME_ID = CRM_LIGHTER_TINT_01;
export const CRM_LIGHT_TINT_02_THEME_ID = CRM_LIGHT_TINT_02;
export const CRM_DARK_TINT_01_THEME_ID = CRM_DARK_TINT_01_COLOR_NAME;
export const CRM_DARKER_TINT_02_THEME_ID = CRM_DARKER_TINT_02_COLOR_NAME;
export const DEV_PRIMARY_THEME_ID = DEV_PRIMARY_COLOR;
export const DEV_LIGHTER_TINT_01_THEME_ID = DEV_LIGHTER_TINT_01_COLOR_NAME;
export const DEV_LIGHT_TINT_02_THEME_ID = DEV_LIGHT_TINT_02;
export const DEV_DARK_TINT_01_THEME_ID = DEV_DARK_TINT_01;
export const DEV_DARKER_TINT_02_THEME_ID = DEV_DARKER_TINT_02;
export const MARKETER_PRIMARY_THEME_ID = MARKETER_PRIMARY_COLOR;
export const MARKETER_LIGHTER_TINT_01_THEME_ID = MARKETER_LIGHTER_TINT_01;
export const MARKETER_LIGHT_TINT_02_THEME_ID = MARKETER_LIGHT_TINT_02;
export const MARKETER_DARK_TINT_01_THEME_ID = MARKETER_DARK_TINT_01;
export const MARKETER_DARKER_TINT_02_THEME_ID = MARKETER_DARKER_TINT_02;
export const PROJECTS_PRIMARY_THEME_ID = PROJECTS_PRIMARY_COLOR;
export const PROJECTS_LIGHTER_TINT_01_THEME_ID = PROJECTS_LIGHTER_TINT_01;
export const PROJECTS_LIGHT_TINT_02_THEME_ID = PROJECTS_LIGHT_TINT_02;
export const PROJECTS_DARK_TINT_01_THEME_ID = PROJECTS_DARK_TINT_01;
export const PROJECTS_DARKER_TINT_02_THEME_ID = PROJECTS_DARKER_TINT_02;
export const PROJECTS_DARKEST_TINT_03_THEME_ID = PROJECTS_DARKEST_TINT_03;
export const PROJECTS_BLUE_THEME_ID = "projects-blue"; // DEPRECATED
export const DEV_ORANGE_THEME_ID = "dev-orange"; // DEPRECATED
export const SNOW_GREY_THEME_ID = SNOW_GREY;
export const BRAND_LIGHT_THEME_ID = BRAND_LIGHT_COLOR_NAME;
export const DARK_SURFACE_THEME_ID = DARK_SURFACE_COLOR_NAME;
export const BACKGROUND_COLOR_OPTIONS = [
    BACKGROUND_GRAY_COLOR_NAME,
    BRAND_WORK_MANAGEMENT_PRIMARY_THEME_ID,
    BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01_THEME_ID,
    BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02_THEME_ID,
    BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01_THEME_ID,
    BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02_THEME_ID,
    BRAND_PRIMARY_YELLOW_THEME_ID,
    BRAND_YELLOW_DARK_TINT_01_THEME_ID,
    BRAND_YELLOW_DARKER_TINT_02_THEME_ID,
    BRAND_PRIMARY_RED_THEME_ID,
    BRAND_RED_DARK_TINT_01_THEME_ID,
    BRAND_RED_DARKER_TINT_02_THEME_ID,
    BRAND_PRIMARY_GREEN_THEME_ID,
    BRAND_GREEN_DARK_TINT_01_THEME_ID,
    BRAND_GREEN_DARKER_TINT_02_THEME_ID,
    WHITE_COLOR_NAME_THEME_ID,
    BRAND_DARK_COLOR_THEME_ID,
    BRAND_BLACK_BACKGROUND_COLOR_THEME_ID,
    CRM_PRIMARY_THEME_ID,
    CRM_LIGHTER_TINT_01_THEME_ID,
    CRM_LIGHT_TINT_02_THEME_ID,
    CRM_DARK_TINT_01_THEME_ID,
    CRM_DARKER_TINT_02_THEME_ID,
    DEV_PRIMARY_THEME_ID,
    DEV_LIGHTER_TINT_01_THEME_ID,
    DEV_LIGHT_TINT_02_THEME_ID,
    DEV_DARK_TINT_01_THEME_ID,
    DEV_DARKER_TINT_02_THEME_ID,
    MARKETER_PRIMARY_THEME_ID,
    MARKETER_LIGHTER_TINT_01_THEME_ID,
    MARKETER_LIGHT_TINT_02_THEME_ID,
    MARKETER_DARK_TINT_01_THEME_ID,
    MARKETER_DARKER_TINT_02_THEME_ID,
    PROJECTS_PRIMARY_THEME_ID,
    PROJECTS_LIGHTER_TINT_01_THEME_ID,
    PROJECTS_LIGHT_TINT_02_THEME_ID,
    PROJECTS_DARK_TINT_01_THEME_ID,
    PROJECTS_DARKER_TINT_02_THEME_ID,
    PROJECTS_DARKEST_TINT_03_THEME_ID,
    FIRST_FOLD_MOTION_INDIGO_COLOR,
    BRAND_LIGHT_THEME_ID,
    DARK_SURFACE_THEME_ID
];
export const themes = {
    [BRAND_WORK_MANAGEMENT_PRIMARY_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]
    },
    [BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]
    },
    [BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02]
    },
    [BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01]
    },
    [BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02]
    },
    [BRAND_PRIMARY_YELLOW_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_PRIMARY_YELLOW]
    },
    [BRAND_YELLOW_DARK_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_YELLOW_DARK_TINT_01]
    },
    [BRAND_YELLOW_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_YELLOW_DARKER_TINT_02]
    },
    [BRAND_PRIMARY_RED_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_PRIMARY_RED]
    },
    [BRAND_RED_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_RED_DARK_TINT_01]
    },
    [BRAND_RED_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_RED_DARKER_TINT_02]
    },
    [BRAND_PRIMARY_GREEN_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_PRIMARY_GREEN]
    },
    [BRAND_GREEN_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_GREEN_DARK_TINT_01]
    },
    [BRAND_GREEN_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_GREEN_DARKER_TINT_02]
    },
    [WHITE_COLOR_NAME_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors.white
    },
    [BRAND_DARK_COLOR_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_DARK_COLOR]
    },
    [BRAND_BLACK_BACKGROUND_COLOR_THEME_ID]: {
        color: Colors.white,
        background: Colors[BRAND_BLACK_BACKGROUND_COLOR]
    },
    [CRM_PRIMARY_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[CRM_PRIMARY_COLOR]
    },
    [CRM_LIGHTER_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[CRM_LIGHTER_TINT_01]
    },
    [CRM_LIGHT_TINT_02_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[CRM_LIGHT_TINT_02]
    },
    [CRM_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[CRM_DARK_TINT_01_COLOR_NAME]
    },
    [CRM_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[CRM_DARKER_TINT_02_COLOR_NAME]
    },
    [DEV_PRIMARY_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[DEV_PRIMARY_COLOR]
    },
    [DEV_LIGHTER_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[DEV_LIGHTER_TINT_01_COLOR_NAME]
    },
    [DEV_LIGHT_TINT_02_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[DEV_LIGHT_TINT_02]
    },
    [DEV_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[DEV_DARK_TINT_01]
    },
    [DEV_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[DEV_DARKER_TINT_02]
    },
    [MARKETER_PRIMARY_THEME_ID]: {
        color: Colors.white,
        background: Colors[MARKETER_PRIMARY_COLOR]
    },
    [MARKETER_LIGHTER_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[MARKETER_LIGHTER_TINT_01]
    },
    [MARKETER_LIGHT_TINT_02_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[MARKETER_LIGHT_TINT_02]
    },
    [MARKETER_DARK_TINT_01_THEME_ID]: {
        color: Colors.white,
        background: Colors[MARKETER_DARK_TINT_01]
    },
    [MARKETER_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[MARKETER_DARKER_TINT_02]
    },
    [PROJECTS_PRIMARY_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[PROJECTS_PRIMARY_COLOR]
    },
    [PROJECTS_LIGHTER_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[PROJECTS_LIGHTER_TINT_01]
    },
    [PROJECTS_LIGHT_TINT_02_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[PROJECTS_LIGHT_TINT_02]
    },
    [PROJECTS_DARK_TINT_01_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors[PROJECTS_DARK_TINT_01]
    },
    [PROJECTS_DARKER_TINT_02_THEME_ID]: {
        color: Colors.white,
        background: Colors[PROJECTS_DARKER_TINT_02]
    },
    [PROJECTS_DARKEST_TINT_03_THEME_ID]: {
        color: Colors.white,
        background: Colors[PROJECTS_DARKEST_TINT_03]
    },
    [PURPLE_THEME_ID]: {
        color: Colors.white,
        background: Colors.purple
    },
    [IRIS_THEME_ID]: {
        color: Colors.white,
        background: Colors[IRIS_COLOR_NAME]
    },
    [ROYAL_THEME_ID]: {
        color: Colors.white,
        background: Colors.royal
    },
    [GREEN_THEME_ID]: {
        color: Colors.white,
        background: Colors.green
    },
    [YELLOW_THEME_ID]: {
        color: Colors.mud,
        background: Colors.yellow
    },
    [ORANGE_THEME_ID]: {
        color: Colors.mud,
        background: Colors.orange
    },
    [RIVERSTONE_THEME_ID]: {
        color: Colors.mud,
        background: Colors[RIVERSTONE_COLOR_NAME]
    },
    [GRAY_THEME_ID]: {
        color: Colors.mud,
        background: Colors[BACKGROUND_GRAY_COLOR_NAME]
    },
    [PINK_THEME_ID]: {
        color: Colors.white,
        background: Colors.pink
    },
    [BLUE_THEME_ID]: {
        color: Colors.white,
        background: Colors["brand-blue"]
    },
    [ENTERPRISE_THEME_ID]: {
        color: Colors.white,
        background: Colors["brand-enterprise-dark"]
    },
    [SPACE_BLUE_THEME_ID]: {
        color: Colors.white,
        background: Colors[SPACE_BLUE_COLOR_NAME]
    },
    [SIGN_UP_WITH_PRODUCTS_BACKGROUND_ID]: {
        color: Colors.white,
        background: Colors[SIGN_UP_WITH_PRODUCTS_COLOR_NAME]
    },
    [GRADIANT_GRAY_THEME_ID]: {
        color: Colors.mud,
        background: Colors["gradiant-gray"]
    },
    [GRADIANT_BRAND_GRAY_THEME_ID]: {
        color: Colors.mud,
        background: Colors[GRADIANT_BRAND_GRAY_COLOR_NAME]
    },
    [GRADIANT_PINK_THEME_ID]: {
        color: Colors.mud,
        background: Colors["gradiant-pink"]
    },
    [GRADIANT_ELEVATE_THEME_ID]: {
        color: Colors.white,
        background: Colors["gradiant-elevate"]
    },
    [LIGHT_BLUE_THEME_ID]: {
        color: Colors.mud,
        background: Colors["light-blue-video-background"]
    },
    [BLACK_THEME_ID]: {
        color: Colors.white,
        background: Colors.mud
    },
    [WHITE_THEME_ID]: {
        color: Colors[SPACE_BLUE_COLOR_NAME],
        background: Colors.white
    },
    [LIME_THEME_ID]: {
        color: Colors.black,
        background: Colors.lime
    },
    [PROJECTS_ORANGE_THEME_ID]: {
        color: Colors.mud,
        background: Colors[PROJECTS_ORANGE_COLOR_NAME]
    },
    [MARKETING_RED_THEME_ID]: {
        color: Colors.mud,
        background: Colors[MARKETING_RED_COLOR_NAME]
    },
    [CRM_GREEN_THEME_ID]: {
        color: Colors.mud,
        background: Colors[CRM_GREEN_COLOR_NAME]
    },
    [DEV_GREEN_THEME_ID]: {
        color: Colors.mud,
        background: Colors[DEV_GREEN_COLOR_NAME]
    },
    [WORKOS_IRIS_THEME_ID]: {
        color: Colors.white,
        background: Colors[WORK_OS_IRIS_COLOR_NAME]
    },
    [SPACE_BLUE_GRADIANT_THEME_ID]: {
        color: Colors.white,
        background: Colors[SPACE_BLUE_GRADIANT_COLOR_NAME]
    },
    [SPACE_BLUE_VIDEO_THEME_ID]: {
        color: Colors.white,
        background: Colors[SPACE_BLUE_VIDEO_ONLY_COLOR_NAME]
    },
    [SPACE_BLUE_VIDEO_DESKTOP_THEME_ID]: {
        color: Colors.white,
        background: Colors[SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME]
    },
    [BACKGROUND_GRAY_THEME_ID]: {
        color: Colors.mud,
        background: Colors[BACKGROUND_GRAY_THEME_ID]
    },
    [ELEVATE_THEME_ID]: {
        color: Colors.white,
        background: Colors[ELEVATE_BANNER_GRADIANT_COLOR_NAME]
    },
    [LIGHT_WORK_OS_IRIS_THEME_ID]: {
        color: Colors.mud,
        background: Colors[LIGHT_WORK_OS_IRIS_COLOR_NAME]
    },
    [LIGHT_DEV_GREEN_THEME_ID]: {
        color: Colors.mud,
        background: Colors[LIGHT_DEV_GREEN_COLOR_NAME]
    },
    [LIGHT_MARKETING_RED_THEME_ID]: {
        color: Colors.mud,
        background: Colors[LIGHT_MARKETING_RED_COLOR_NAME]
    },
    [LIGHT_CRM_GREEN_THEME_ID]: {
        color: Colors.mud,
        background: Colors[LIGHT_CRM_GREEN_COLOR_NAME]
    },
    [LIGHT_PROJECTS_ORANGE_THEME_ID]: {
        color: Colors.mud,
        background: Colors[LIGHT_PROJECTS_ORANGE_COLOR_NAME]
    },
    [SNOW_GREY_THEME_ID]: {
        color: Colors[BRAND_BLACK_BACKGROUND_COLOR],
        background: Colors.snow
    },
    // DEPRECATED
    [PROJECTS_BLUE_THEME_ID]: {
        color: Colors.mud,
        background: Colors[PROJECTS_BLUE_COLOR_NAME]
    },
    [DEV_ORANGE_THEME_ID]: {
        color: Colors.mud,
        background: Colors[DEV_ORANGE_COLOR_NAME]
    },
    [CRM_DARK_TINT_01]: {
        color: Colors.white,
        background: Colors[CRM_DARK_TINT_01_COLOR_NAME]
    },
    [CRM_DARKER_TINT_02]: {
        color: Colors.white,
        background: Colors[CRM_DARKER_TINT_02_COLOR_NAME]
    },
    [DEV_LIGHTER_TINT_01]: {
        color: Colors.white,
        background: Colors[DEV_LIGHTER_TINT_01]
    },
    [SPRING_GREEN]: {
        color: Colors.white,
        background: Colors[SPRING_GREEN]
    },
    [ROUGE_RED]: {
        color: Colors.white,
        background: Colors[ROUGE_RED]
    },
    [RACKLEY_BLUE]: {
        color: Colors.black,
        background: Colors[RACKLEY_BLUE]
    },
    [FIRST_FOLD_MOTION_INDIGO_COLOR]: {
        color: Colors.white,
        background: Colors[FIRST_FOLD_MOTION_INDIGO_COLOR]
    },
    [MIDNIGHT_PURPLE_COLOR]: {
        color: Colors.white,
        background: Colors[MIDNIGHT_PURPLE_COLOR]
    },
    [INDIGO_BLUE_COLOR]: {
        color: Colors.white,
        background: Colors[INDIGO_BLUE_COLOR]
    },
    [AMETHYST_PURPLE_COLOR]: {
        color: Colors.white,
        background: Colors[AMETHYST_PURPLE_COLOR]
    },
    [SAPPHIRE_PURPLE_COLOR]: {
        color: Colors.white,
        background: Colors[SAPPHIRE_PURPLE_COLOR]
    },
    [DEEP_NAVY_BLUE_COLOR]: {
        color: Colors.white,
        background: Colors[DEEP_NAVY_BLUE_COLOR]
    },
    [BRAND_LIGHT_THEME_ID]: {
        color: Colors[BRAND_BLACK_TEXT_COLOR],
        background: Colors[BRAND_LIGHT_COLOR_NAME]
    },
    [DARK_SURFACE_THEME_ID]: {
        color: Colors.white,
        background: Colors[DARK_SURFACE_COLOR_NAME]
    }
};
export const getTheme = (themeId)=>themes === null || themes === void 0 ? void 0 : themes[themeId];
export const isDarkTheme = (themeId)=>{
    var _themes_themeId;
    return (themes === null || themes === void 0 ? void 0 : (_themes_themeId = themes[themeId]) === null || _themes_themeId === void 0 ? void 0 : _themes_themeId.color.toLowerCase()) === Colors.white.toLowerCase();
};
